const Heading = ({ typeHeading = "h1", content, primary= true, size, style, color }) => {

    switch (typeHeading) {
        case "h1":
            return <h1 className={`${size ? size : "text-4xl"} font-bold ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h1>;
        case "h2":
            return <h2 className={`${size ? size : "text-3xl"} font-semibold ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h2>;
        case "h3":
            return <h3 className={`${size ? size : "text-2xl"} font-medium ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h3>;
        case "h4":
            return <h4 className={`${size ? size : "text-xl"} font-medium ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h4>;
        case "h5":
            return <h5 className={`${size ? size : "text-lg"} font-normal ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h5>;
        case "h6":
            return <h6 className={`${size ? size : "text-base"} font-normal ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h6>;
        default:
            return <h1 className={`${ size ? size : "text-4xl" } font-medium ${ color ? color : primary ? "text-slate-200" : "text-slate-500"}`} style={style}>{content}</h1>; // fallback to h1
    }
};

export default Heading;
