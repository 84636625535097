import React from 'react';
import { createRoot } from "react-dom/client";
import { AuthProvider } from './context/AuthContext';

// Redux setting
import store from './app/store.js'
import { Provider } from 'react-redux'

import App from './App';
import LoadingProvider from "./context/loadingContext";
import {DrawerProvider} from "./context/DrawerContext";
import {Toaster} from "sonner";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store} >
    <Toaster richColors position="top-center" />
    <AuthProvider>
        <LoadingProvider>
            <DrawerProvider>
                <App />
            </DrawerProvider>
        </LoadingProvider>
    </AuthProvider> 
  </Provider>
    
);