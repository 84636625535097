import React, { useState, useEffect, useMemo } from 'react';

const formatNumberWithZero = (num) => {
    return num < 10 ? `0${num}` : `${num}`;
};

export const Countdown = () => {
    const endDate = useMemo(() => new Date('2024-11-18T00:00:00Z'), []);

    const [remainingTime, setRemainingTime] = useState(endDate.getTime() - Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = Date.now();
            const remaining = endDate.getTime() - currentTime;

            if (remaining <= 0) {
                clearInterval(interval);
            }

            setRemainingTime(remaining);
        }, 1000);

        return () => clearInterval(interval);
    }, [endDate]);

    const days = formatNumberWithZero(Math.max(0, Math.floor(remainingTime / (1000 * 60 * 60 * 24))));
    const hours = formatNumberWithZero(Math.max(0, Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))));
    const minutes = formatNumberWithZero(Math.max(0, Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))));
    const seconds = formatNumberWithZero(Math.max(0, Math.floor((remainingTime % (1000 * 60)) / 1000)));

    return (
        <ul className="absolute bottom-1/4 left-1/2 flex items-center lg:gap-8 gap-4 transform -translate-x-1/2">
            <li className={`lg:text-lg text-sm text-white font-extrabold flex flex-col items-center justify-center gap-4 lg:p-4 p-2`}>
                {days}
                <span className={`block lg:text-md font-medium lowercase text-sm`}>Jours</span>
            </li>
            <li className={`lg:text-lg text-sm text-white font-extrabold flex flex-col items-center justify-center gap-4 lg:p-4 p-2`}>
                {hours}
                <span className={`block lg:text-md font-medium lowercase text-sm`}>Heures</span>
            </li>
            <li className={`lg:text-lg text-sm text-white font-extrabold flex flex-col items-center justify-center gap-4 lg:p-4 p-2`}>
                {minutes}
                <span className={`block lg:text-md font-medium lowercase text-sm`}>Minutes</span>
            </li>
            <li className={`lg:text-lg text-sm text-white font-extrabold flex flex-col items-center justify-center gap-4 lg:p-4 p-2`}>
                {seconds}
                <span className={`block lg:text-md font-medium lowercase text-sm`}>Secondes</span>
            </li>
        </ul>
    );
};
