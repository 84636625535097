import {createContext, useEffect, useState} from "react";

export const DrawerContext = createContext(false);

export const DrawerProvider = ({ children }) => {
    const [dataId, setDataId] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerClose = () => setDrawerOpen(false);

    useEffect(() => {
        if (drawerOpen) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [drawerOpen])

    return (
        <DrawerContext.Provider value={{drawerOpen, setDrawerOpen, setDataId, dataId}}>
            {children}
            { drawerOpen && <span className="fixed left-0 top-0 h-screen bg-slate-50/20 w-full z-30" onClick={handleDrawerClose}></span> }
        </DrawerContext.Provider>
    )
}