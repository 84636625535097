const Ping = ({ isText}) => {
    return (
        <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform flex flex-col gap-4 items-center`}>
            <span className="relative flex h-3 w-3">
              <span
                  className="animate-ping absolute inline-flex h-full w-full rounded-full bg-slate-100 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-slate-200"></span>
            </span>
            { isText && <span className="text-xs text-slate-50 text-center animate-pulse">Mobalicorp</span> }
        </div>
    );
};

export default Ping;
