import React, { useState } from "react";
import Heading from "../atoms/Heading";
import { useForm } from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {FiCornerDownRight} from "react-icons/fi";
import {toast} from "sonner";

const Stepper = () => {
    const [step, setStep] = useState(1);
    const [quoteType, setQuoteType] = useState(null);
    const [clientType, setClientType] = useState(null);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const nextStep = () => setStep((prev) => prev + 1);
    const prevStep = () => setStep((prev) => prev - 1);

    const handleQuoteTypeSelection = (type) => {
        setQuoteType(type);
        nextStep();
    };

    const handleClientTypeSelection = (type) => {
        setClientType(type);
    };

    const selectedBudget = watch("promotionDetails.budget");
    const selectedServices = watch("promotionDetails.services");
    const selectedTypePrestation = watch("studioDetails.typePrestation");

    let navigate = useNavigate();

    const onSubmit = (data) => {
        let formData = {};

        const clientInfos = {
            type: clientType,
            nom: data.clientInfo.nom,
            email: data.clientInfo.email,
            telephone: data.clientInfo.telephone

        }

        if (quoteType === "promotion") {
            formData = {
                type: "promotion",
                client: clientInfos,
                budget: data.promotionDetails.budget,
                services: data.promotionDetails.services,
                objectif: data.promotionDetails.objectifs,
            }
        }

        if (quoteType === "studio") {
            const typePrestation = data.studioDetails.typePrestation;
            let besoins = [];

            // Gérer les besoins en fonction du type de prestation
            if (typePrestation === "Studio d'enregistrement") {
                besoins = data.studioDetails.besoinsEnregistrement || [];
            } else if (typePrestation === "Studio photo") {
                besoins = data.studioDetails.besoinsPhoto || [];
            }

            formData = {
                type: "studio",
                client: clientInfos,
                prestation: data.studioDetails.typePrestation,
                participants: data.studioDetails.participants,
                besoins: besoins
            }
        }

        try {
            console.log("Données envoyer", formData);
            toast.success("Votre dévis à bien été envoyé chez la mobalicorp")
            return navigate("/");
        } catch (error) {
            console.error(error);
            return toast.error("Quelque chose s'est mal déroulé, veuillez ressayer plus tard");
        }
    };

    return (
        <div className="lg:w-1/2 text-slate-700">
            <div className="absolute top-10">
                {quoteType && step !== 1 ?
                    <div className="flex items-start gap-2">
                        <span className="text-sm block"><FiCornerDownRight/></span>
                        <p className="text-slate-500 text-sm">
                            Vous êtes en train de créer un devis <span
                            className="text-orange-400 lowercase">{quoteType}</span> pour {clientType === "entreprise" ? "une" : "un"}
                            <span className="text-orange-400 lowercase"> {clientType}</span>
                        </p>
                    </div>
                    :
                    <Link to="/"
                          className="text-slate-500 text-sm h-8 border border-slate-200 flex items-center justify-center px-2 rounded-sm shadow-sm shadow-slate-200">Revenir
                        au site</Link>
                }
            </div>
            {/* Step 1: Quote Type Selection */}
            {step === 1 && (
                <div className="flex flex-col gap-4">
                    <Heading typeHeading="h2" content="Étape 1 : Choix du Type de Devis" color="text-slate-900"/>
                    <ul className="flex gap-4 flex-wrap">
                        <li className="border border-slate-200 rounded-sm">
                            <button className="h-8 w-full flex items-center px-4 text-sm text-slate-500 justify-center" onClick={() => handleQuoteTypeSelection("promotion")}>
                                Devis Promotion
                            </button>
                        </li>
                        <li className="border border-slate-200 rounded-sm">
                            <button className="h-8 w-full flex items-center px-4 text-sm text-slate-500 justify-center" onClick={() => handleQuoteTypeSelection("studio")}>
                                Devis Réservation de Studio
                            </button>
                        </li>
                    </ul>
                </div>
            )}

            {/* Step 2: Client Type Selection */}
            {step === 2 && (
                <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                    <Heading typeHeading="h2" content="Étape 2 : Choix du Type de Client" color="text-slate-900"/>
                    <ul className="flex gap-4">
                        <li className={`rounded-sm ${clientType === "particulier" ? "border-none":"border border-slate-200"}`}>
                            <button className={`h-8 w-full flex items-center px-4 text-sm justify-center ${clientType === "particulier" ? "bg-orange-400 text-slate-50":"bg-transparent text-slate-500"}`}
                                    onClick={() => handleClientTypeSelection("particulier")}>
                                Particulier
                            </button>
                        </li>
                        <li className="border border-slate-200 rounded-sm">
                            <button className={`h-8 w-full flex items-center px-4 text-sm justify-center ${clientType === "entreprise" ? "bg-orange-400 text-slate-50":"bg-transparent text-slate-500"}`}
                                    onClick={() => handleClientTypeSelection("entreprise")}>
                                Entreprise
                            </button>
                        </li>
                    </ul>
                    <div className="absolute bottom-10 w-1/2 flex items-center justify-between">
                        <button type="button" onClick={prevStep}
                                className="h-10 border border-slate-300 p-2 rounded-sm text-sm">Précédent
                        </button>
                        {clientType &&
                            <button type="button" onClick={nextStep}
                                    className="h-10 bg-orange-400 text-slate-50 p-2 rounded-sm text-sm">Continuer
                            </button>
                        }
                    </div>
                </div>
            )}

            {/* Step 3: Personal Information */}
            {step === 3 && (
                <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                    <Heading typeHeading="h2" content="Étape 3 : Informations personnelle" color="text-slate-900"/>
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex gap-2 lg:flex-row flex-col">
                            <label className="lg:w-1/2 w-full border border-slate-200">
                                <input
                                    className="w-full h-8 px-2 rounded-sm text-slate-500 text-sm"
                                    type="text"
                                    placeholder={clientType === "entreprise" ? "Nom de l'organisme" : "Entrer votre nom"}
                                    {...register("clientInfo.nom", { required: true })}
                                />
                                {errors.clientInfo?.nom && <span>Nom requis</span>}
                            </label>
                            <label className="lg:w-1/2 w-full border border-slate-200">
                                <input
                                    className="w-full h-8 px-2 rounded-sm text-slate-500 text-sm"
                                    type="email"
                                    placeholder="Email"
                                    {...register("clientInfo.email", { required: true })}
                                />
                                {errors.clientInfo?.email && <span>Email requis</span>}
                            </label>
                        </div>

                        <label className="w-full border border-slate-200">
                            <input
                                className="w-full h-8 px-2 rounded-sm text-slate-500 text-sm"
                                type="tel"
                                placeholder="Numéro de téléphone"
                                {...register("clientInfo.telephone", { required: true })}
                            />
                            {errors.clientInfo?.telephone && <span>Téléphone requis</span>}
                        </label>
                        <div className="absolute bottom-10 w-1/2 flex items-center justify-between">
                            <button type="button" onClick={prevStep} className="h-10 border border-slate-300 p-2 rounded-sm text-sm">Précédent</button>
                            <button type="button" onClick={nextStep} className="h-10 bg-orange-400 text-slate-50 p-2 rounded-sm text-sm">Continuer</button>
                        </div>
                    </form>
                </div>
            )}

            {/* Step 4: Quote Details */}
            {step === 4 && quoteType === "promotion" && (
                <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                    <Heading typeHeading="h2" content="Étape 4 : Détails du Devis de Promotion" color="text-slate-900"/>
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-semibold">Services souhaités :</p>
                            <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                {["Gestion des réseaux sociaux", "Publicité en ligne", "Création de contenu", "SEO/SEA", "Analyse de performance"].map((service) => (
                                    <li key={service} className={`rounded-sm h-8 flex items-center justify-start px-2 relative 
                        ${selectedServices === service ? "bg-orange-400 text-slate-50 border-none" : "text-slate-500 bg-transparent border border-slate-300"}`}>
                                        <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                            <input
                                                className="sr-only"
                                                type="radio"
                                                {...register("promotionDetails.services", { required: true })}
                                                value={service}
                                            />
                                            {service}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-semibold">Budget estimé :</p>
                            <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                {["Moins de 500 €", "500 € - 1000 €", "1000 € - 5000 €", "Plus de 5000 €"].map((budget) => (
                                    <li
                                        key={budget}
                                        className={`rounded-sm h-8 flex items-center justify-start px-2 relative 
                        ${selectedBudget === budget ? "bg-orange-400 text-slate-50 border-none" : "text-slate-500 bg-transparent border border-slate-300"}`}
                                    >
                                        <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                            <input
                                                className="sr-only"
                                                type="radio"
                                                {...register("promotionDetails.budget", {required: true})}
                                                value={budget}
                                            />
                                            {budget}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="flex flex-col gap-2">
                            <label className="text-sm font-semibold">Objectifs de la campagne :</label>
                            <textarea
                                placeholder="Objectifs"
                                {...register("promotionDetails.objectifs")}
                                className="h-24 w-full p-2 text-sm border border-slate-200 rounded-sm focus:outline-none"
                            />
                        </div>

                        <div className="lg:absolute lg:bottom-10 lg:w-1/2 flex items-center justify-between">
                            <button type="button" onClick={prevStep}
                                    className="h-10 border border-slate-300 p-2 rounded-sm text-sm">Précédent
                            </button>
                            <button type="submit" className="h-10 bg-orange-400 text-slate-50 p-2 rounded-sm text-sm">
                                Créer mon devis
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {step === 4 && quoteType === "studio" && (
                <div className="flex flex-col gap-4 lg:h-auto min-h-[70vh]">
                    <Heading typeHeading="h2" content="Étape 4 : Détails du Devis de Réservation de Studio" color="text-slate-900"/>
                    <form className="flex flex-col gap-4 pb-10" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-semibold">Type de prestation :</p>
                            <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                {["Studio d'enregistrement", "Studio photo", "Studio de tournage vidéo", "Événement privé", "Événement d'entreprise", "Studio de diffusion en direct"].map((typePrestation) => (
                                    <li
                                        key={typePrestation}
                                        className={`rounded-sm h-8 flex items-center justify-start px-2 relative 
                            ${selectedTypePrestation === typePrestation ? "bg-orange-400 text-slate-50 border-none" : "text-slate-500 bg-transparent border border-slate-300"}`}
                                    >
                                        <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                            <input
                                                className="sr-only"
                                                type="radio"
                                                {...register("studioDetails.typePrestation", {required: true})}
                                                value={typePrestation}
                                            />
                                            {typePrestation}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div
                            className="order-first flex lg:flex-row flex-col gap-2 border border-slate-300 rounded-sm p-6 items-center">
                            <label>
                                <span className="text-sm font-semibold">Date et créneau horaire souhaités :</span>
                                <input
                                    type="date"
                                    {...register("studioDetails.date")}
                                    className="w-full h-12 px-2 border border-slate-200 rounded-sm bg-slate-100"
                                />
                            </label>
                            <label>
                                <span className="text-sm font-semibold">Nombre de participants :</span>
                                <input
                                    type="number"
                                    {...register("studioDetails.participants")}
                                    className="w-full h-12 px-2 border border-slate-300 rounded-sm bg-slate-100"
                                />
                            </label>
                        </div>

                        {/* Besoins techniques spécifiques */}
                        {selectedTypePrestation === "Studio d'enregistrement" && (
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Besoins techniques pour Studio d'enregistrement
                                    :</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Microphones de haute qualité", "Table de mixage", "Isolation phonique", "Équipement pour voix-off", "Enregistrement multi-pistes"]?.map((besoin) => (
                                        <li key={besoin}
                                            className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsEnregistrement")) && watch("studioDetails.besoinsEnregistrement")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="checkbox"
                                                    {...register("studioDetails.besoinsEnregistrement")}
                                                    value={besoin}
                                                />
                                                {besoin}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {selectedTypePrestation === "Studio photo" && (
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Besoins techniques pour Studio photo :</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Éclairage professionnel", "Toile de fond", "Appareils photo HD", "Trépieds", "Accessoires pour séance photo"]?.map((besoin) => (
                                        <li key={besoin}
                                            className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsPhoto")) && watch("studioDetails.besoinsPhoto")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="checkbox"
                                                    {...register("studioDetails.besoinsPhoto")}
                                                    value={besoin}
                                                />
                                                {besoin}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {selectedTypePrestation === "Studio de tournage vidéo" && (
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Besoins techniques pour Studio de tournage vidéo
                                    :</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Caméras 4K", "Éclairage vidéo", "Fond vert", "Équipement de stabilisation", "Post-production"]?.map((besoin) => (
                                        <li key={besoin}
                                            className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsVideo")) && watch("studioDetails.besoinsVideo")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="checkbox"
                                                    {...register("studioDetails.besoinsVideo")}
                                                    value={besoin}
                                                />
                                                {besoin}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {selectedTypePrestation === "Événement privé" && (
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Besoins techniques pour un événement privé :</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Sonorisation", "Éclairage d'ambiance", "Projecteurs", "Caméras de diffusion"]?.map((besoin) => (
                                        <li key={besoin}
                                            className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsTechniquesPrivate")) && watch("studioDetails.besoinsTechniquesPrivate")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="checkbox"
                                                    {...register("studioDetails.besoinsTechniquesPrivate", {required: false})}
                                                    value={besoin}
                                                />
                                                {besoin}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {selectedTypePrestation === "Événement d'entreprise" && (
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Besoins techniques pour un événement d'entreprise
                                    :</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Sonorisation", "Éclairage professionnel", "Caméra de diffusion en direct", "Écran géant"]?.map((besoin) => (
                                        <li key={besoin}
                                            className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsTechniques")) && watch("studioDetails.besoinsTechniques")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="checkbox"
                                                    {...register("studioDetails.besoinsTechniques", {required: false})}
                                                    value={besoin}
                                                />
                                                {besoin}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {selectedTypePrestation === "Studio de diffusion en direct" && (
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">Besoins techniques pour Studio de diffusion en
                                    direct :</p>
                                <ul className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                                    {["Connexion Internet haut débit", "Caméra pour diffusion en direct", "Éclairage vidéo", "Système audio de qualité"]?.map((besoin) => (
                                        <li key={besoin}
                                            className={`h-8 flex items-center justify-start px-2 rounded-sm ${Array.isArray(watch("studioDetails.besoinsDiffusion")) && watch("studioDetails.besoinsDiffusion")?.includes(besoin) ? "bg-orange-400 text-slate-50" : "border border-slate-300"}`}>
                                            <label className="flex items-center gap-2 text-sm w-full cursor-pointer">
                                                <input
                                                    className="sr-only"
                                                    type="checkbox"
                                                    {...register("studioDetails.besoinsDiffusion", {required: false})}
                                                    value={besoin}
                                                />
                                                {besoin}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Submit Button */}
                        <div className="lg:absolute lg:bottom-10 lg:w-1/2 flex items-center justify-between">
                            <button type="button" onClick={prevStep}
                                    className="h-10 border border-slate-300 p-2 rounded-sm text-sm">Précédent
                            </button>
                            <button type="submit"
                                    className="h-10 bg-orange-400 text-slate-50 p-2 rounded-sm text-sm">Créer une reservation
                            </button>
                        </div>
                    </form>
                </div>
            )}

        </div>
    );
};

export default Stepper;
