// Components
import HeadCustom from "../HeadCustom";
import ArticleComp from "../ui/ArticleComp";
import Ping from "../ui/atoms/Ping";
import {Countdown} from "../ui/organisms/CountDown";
import Heading from "../ui/atoms/Heading";

const Maintenance = () => {
    return (
        <>
            <HeadCustom headtitle={"Site en maintenance"} />
            <ArticleComp height="h-screen" relative >
                <div className="h-screen">
                    <div className="flex gap-2 flex-col items-center justify-center absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 transform lg:max-w-96 w-full">
                        <Heading content="MobaliCorp se refait une beauté !" typeHeading="h2" size="small"
                                 style={{color: "#fb923c"}}/>
                        <p className="text-md text-orange-400 text-center"></p>
                        <p className="text-sm text-slate-400 text-center">Nous apportons des améliorations pour une
                            meilleure expérience. Merci de votre patience, et à bientôt !</p>
                        <a
                            href="mailto:mobali@mobalicorps.com"
                            className="underline text-sm text-slate-200"
                        >Contactez-nous</a>
                    </div>
                    <Ping/>
                    <Countdown/>
                </div>
            </ArticleComp>
        </>
    )
}

export default Maintenance;