import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './routes/AnimatedRoutes';
// import CSS
import './assets/css/index.scss';

export default function App() {

  return (
    <Router basename = {process.env.PUBLIC_URL}>
      <AnimatedRoutes />
    </Router>
  )
}