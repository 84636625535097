const ArticleComp = ({ children, backgroundColor, relative= true, wrapper= true, padding, height, scrollbarHiden }) => {
    return (
        <article className={`${height ? height : "min-h-screen"} w-full ${backgroundColor && backgroundColor} ${ scrollbarHiden && "lg:overflow-hidden"}`}>
            <div className={`${wrapper ? "wrapper" : ""} ${relative ? "relative" : ""} ${padding ? "py-10" : "py-0"}`}>
                {children}
            </div>
        </article>
    );
};

export default ArticleComp;
